<template>
  <v-container>
    <v-template>
      <v-row class="header">
        <v-col cols="12" sm="6" md="4" class="d-flex align-center">
          <title-vue :title="title" />
        </v-col>
        <v-col cols="12" sm="6" md="8" class="d-flex justify-end">
          <status-badge-vue :status="partner.status" />
          <div class="d-flex justify-center">
            <v-menu offset-y>
              <template v-slot:activator="{on, attrs}">
                <v-btn elevation="0" class="py-1 ml-2 white--text rounded-lg" v-bind="attrs" v-on="on" color="#E5183D">
                  Baixar QRCODE
                </v-btn>
              </template>
              <v-list>
                <v-list-item :class="`${partner.digitalAccountNumber == '' ? 'disabled':''}`">
                  <v-list-title @click="handleQRCODEStatic()">Baixar QRCODE estático</v-list-title>
                </v-list-item>
                <v-list-item>
                  <v-list-title @click="handleQRCODEEnvite()">Baixar QRCODE de indicação</v-list-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </div>
        </v-col>
      </v-row>
      <v-row class="mt-2">
        <v-col cols="12">
          <v-card>
            <v-card-text>
              <p class="subtitle-2">Dados do parceiro</p>
              <v-row class="mb-4">
                <v-col cols="12" md="4">
                  <input-text-vue
                    label="Nome *"
                    :required="true"
                    :disabled="loading"
                    :readonly="readonly"
                    v-model="partner.name"
                  />
                </v-col>
                <v-col cols="12" md="4">
                  <input-text-vue
                    :label="
                      partner.typeCompany == 'PJ' ? 'Nome fantasia' : 'Como quer ser chamado?'
                    "
                    :required="partner.typeCompany == 'PJ'"
                    :readonly="readonly"
                    v-model="partner.nickName"
                  />
                </v-col>

                <v-col cols="12" md="4">
                  <input-text-vue
                    :label="partner.typeCompany == 'PJ' ? 'CNPJ *' : 'CPF *'"
                    :required="true"
                    :disabled="loading"
                    :readonly="readonly"
                    v-model="partner.document"
                    v-mask="partner.typeCompany !== 'PJ' ? '###.###.###-##' : '##.###.###/####-##'"
                  />
                </v-col>
                <v-col cols="12" md="2">
                  <v-select
                    :items="person"
                    v-model="partner.typeCompany"
                    :disabled="loading"
                    :readonly="readonly"
                    item-text="text"
                    item-value="value"
                  />
                </v-col>
                <v-col cols="12" md="3">
                  <input-text-vue
                    label="Telefone *"
                    :required="true"
                    :disabled="loading"
                    :readonly="readonly"
                    v-model="partner.phone"
                    v-mask="'(##) #####-####'"
                  />
                </v-col>
                <v-col cols="12" md="5">
                  <input-text-vue
                    label="Email *"
                    :required="true"
                    :disabled="loading"
                    :readonly="readonly"
                    v-model="partner.email"
                  />
                </v-col>
                <v-col cols="12" md="5">
                  <v-text-field
                    label="CNAE"
                    :required="partner.typeCompany == 'PJ'"
                    :disabled="loading"
                    :readonly="readonly"
                    v-model="partner.cnaeCode"
                    @keydown="handleBloquedNumber($event)"
                  />
                </v-col>

                <v-col cols="12" md="3">
                  <v-autocomplete
                    label="Tipo de parceiro"
                    v-model="partner.category.id"
                    :items="categories"
                    :disabled="loading"
                    :readonly="readonly"
                    item-text="name"
                    item-value="id"
                  />
                </v-col>

                <v-col cols="12" md="3">
                  <v-text-field
                    label="Tipo de parceiro"
                    :disabled="loading"
                    :readonly="readonly"
                    v-model="partner.category.name"
                  />
                </v-col>
              </v-row>

              <p class="subtitle-2">Dados de endereço</p>
              <v-row class="mb-4">
                <v-col cols="2">
                  <v-text-field
                    label="CEP *"
                    required
                    :disabled="loading"
                    v-model="partner.address.zipCode"
                    v-mask="'#####-###'"
                    :readonly="readonly"
                  />
                </v-col>
                <v-col cols="4">
                  <input-text-vue
                    label="Logradouro *"
                    :required="true"
                    :disabled="loading"
                    :readonly="readonly"
                    v-model="partner.address.street"
                  />
                </v-col>
                <v-col cols="2">
                  <input-text-vue
                    label="Número"
                    :disabled="loading"
                    :readonly="readonly"
                    v-model="partner.address.addressNumber"
                  />
                </v-col>
                <v-col cols="4">
                  <input-text-vue
                    label="Complemento"
                    :disabled="loading"
                    :readonly="readonly"
                    v-model="partner.address.complement"
                  />
                </v-col>
                <v-col cols="4">
                  <input-text-vue
                    label="Bairro *"
                    :required="true"
                    :disabled="loading"
                    :readonly="readonly"
                    v-model="partner.address.neighborhood"
                  />
                </v-col>
                <v-col cols="2">
                  <input-text-vue
                    label="UF *"
                    :required="true"
                    :disabled="true"
                    :readonly="true"
                    v-model="partner.address.state"
                  />
                </v-col>
                <v-col cols="4">
                  <input-text-vue
                    label="Cidade *"
                    :required="true"
                    :disabled="true"
                    :readonly="true"
                    v-model="partner.address.cityName"
                  />
                </v-col>
              </v-row>

              <p class="subtitle-2">Horário de Funcionamento</p>
              <v-row class="mb-4">
                <v-col cols="12" md="12">
                  <v-data-table
                    :headers="headersHours"
                    :items="partner.businessHours"
                    :items-per-page="5"
                    class="elevation-1"
                  >
                    <template v-slot:body="{ items }">
                      <tbody>
                        <tr v-for="(item, index) in items" :key="index">
                          <td class="text-center">{{ item.day }}</td>
                          <td class="text-center">{{ item.startTime }}</td>
                          <td class="text-center">{{ item.endTime }}</td>
                        </tr>
                      </tbody>
                    </template>
                  </v-data-table>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>

      <v-row v-if="!loading">
        <v-col class="d-flex justify-start">
          <button-vue dark color="red" class="mr-4" type="cancel" @click="handleCancel"
            >Voltar</button-vue
          >
        </v-col>
      </v-row>
    </v-template>
  </v-container>
</template>

<script lang="ts">
import { defineComponent } from "vue";

import ButtonVue from "@/components/button/Button.vue";
import TitleVue from "@/components/title/Title.vue";
import InputTextVue from "@/components/inputs/InputText.vue";

import { GetPartner } from "@/services/partners/GetPartner";

import { format } from "date-fns";
import { PartnerDetailsType } from "@/types/partner/Partner";
import { CategoryType } from "@/types/category/Category";
import QrCode from 'qrcode'
import StatusBadgeVue from "@/components/statusBadge/StatusBadge.vue";

export default defineComponent({
  components: { ButtonVue, TitleVue, InputTextVue, StatusBadgeVue },
  data() {
    return {
      errorsName: "",
      headers: [
        {
          text: "Nome",
          align: "center",
          value: "name",
        },
        {
          text: "CPF",
          align: "center",
          value: "cpf",
          textAlign: "center",
        },
        {
          text: "RG",
          align: "center",
          value: "rg",
        },
      ],
      headersHours: [
        {
          text: "Dia",
          align: "center",
          value: "day",
        },
        {
          text: "Abertura",
          align: "center",
          value: "startTime",
        },
        {
          text: "Fechamento",
          align: "center",
          value: "endTime",
        },
      ],
      loading: false,
      show: false,
      errors: "",
      title: "Novo parceiro",
      person: [
        {
          id: 1,
          text: "Pessoa Fisica",
          value: "PF",
        },
        {
          id: 2,
          text: "Pessoa Jurídica",
          value: "PJ",
        },
      ],
      readonly: false,
      categories: { name: "" } as CategoryType,
      day: [
        "Domingo",
        "Segunda-feira",
        "Terça-feira",
        "Quarta-feira",
        "Quinta-feira",
        "Sexta-feira",
        "Sábado",
      ],
      associateName: "",
      associateCpf: "",
      associateRg: "",
      businessHoursDay: "",
      businessHoursStartTime: "",
      businessHoursEndTime: "",
      cnae: [],
      partner: {
        id: "",
        name: "",
        status: "PENDING",
        nickName: "",
        tax: 5,
        typeCompany: "PJ",
        category: {
          name: "",
          id: "",
        },
        phone: "",
        email: "",
        document: "",
        pixkey: "",
        cnaeCode: "",
        address: {
          state: "",
          street: "",
          addressNumber: "",
          neighborhood: "",
          complement: "",
          zipCode: "",
          cityName: "",
          ibge: "",
        },
        users: [],
        associate: [],
        businessHours: [],
        createdAt: "",
        digitalAccountNumber: "",
        digitalAccountDocument: "",
        indicationKey: ""
      } as PartnerDetailsType,
    };
  },
  methods: {
    validateNumber() {
      this.partner.digitalAccountNumber = this.partner.digitalAccountNumber.replace(/\D/g, "");
    },
    async handleLoadPartner() {
      this.readonly = true;
      this.title = "Detalhes do parceiro";
      this.loading = true;
      const getPartner = new GetPartner();
      const partner = await getPartner.execute(this.$route.params.id);

      this.partner.id = partner.id || "";
      this.partner.name = partner.name || "";

      this.partner.status = partner.status;

      this.partner.createdAt = partner.createdAt || "";
      this.partner.phone = this.formatPhone(partner.phone) || "";
      this.partner.email = partner.email || "";
      this.partner.document = this.formatDocument(partner.document) || "";

      if (partner.document.length > 11) {
        this.partner.typeCompany = "PJ";
      } else {
        this.partner.typeCompany = "PF";
      }

      if (partner.category) {
        this.partner.category.id = partner.category.id || "";
        this.partner.category.name = partner.category.name || "";
      }

      this.partner.address.street = partner.address.street || "";
      this.partner.address.addressNumber = partner.address.addressNumber || "";
      this.partner.address.neighborhood = partner.address.neighborhood || "";
      this.partner.address.complement = partner.address.complement || "";
      this.partner.address.zipCode = this.formatZipCod(partner.address.zipCode) || "";
      this.partner.address.cityName = partner.address.cityName || "";
      this.partner.address.state = partner.address.state || "";

      this.partner.tax = Number(partner.tax) || 5;
      this.partner.pixkey = partner.pixkey || "";
      this.partner.digitalAccountDocument = partner.digitalAccountDocument || "";
      this.partner.digitalAccountNumber = partner.digitalAccountNumber || "";
      this.partner.cnaeCode = partner.cnaeCode || "";

      this.partner.associate = partner.associate || { name: "", cpf: "", rg: "" };
      this.partner.businessHours = partner.businessHours || { day: "", startTime: "", endTime: "" };

      this.partner.users = partner.users;

      this.partner.indicationKey = partner.indicationKey || ""; 

      this.loading = false;
    },

    handleCancel() {
      this.$router.push("/partners");
    },

    formatPhone: (value: string): string => {
      if (!value) return "";
      const phone = "(" + value.substr(0, 2) + ") " + value.substr(2, 5) + "-" + value.substr(7, 8);
      return phone;
    },
    formatDate: (date: string): string => {
      const dateFormated = format(new Date(date), "dd/MM/yyyy HH:mm:ss");
      return dateFormated;
    },
    formatZipCod: (cep: string): string => {
      return cep.replace(/(\d{5})(\d{3})/, "$1-$2");
    },
    formatDocument: (document: string): string => {
      if (document.length === 11) {
        return document.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, "$1.$2.$3-$4");
      }
      if (document.length === 14) {
        return document.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, "$1.$2.$3/$4-$5");
      }
      return document;
    },
    handleFilterElement(item: any, query: any) {
      return (
        item.descricao.toLocaleLowerCase().indexOf(query.toLocaleLowerCase()) > -1 ||
        item.id.indexOf(query) > -1
      );
    },
    handleAddAssociate() {
      if (this.associateCpf == "" || this.associateName == "" || this.associateRg == "") {
        return;
      }
      const indentifyElement = this.partner.associate.some(
        (objeto) => objeto.cpf == this.associateCpf
      );
      if (indentifyElement) {
        this.errors = "Este CPF já foi cadastrado";

        return;
      }

      this.partner.associate.push({
        name: this.associateName,
        cpf: this.associateCpf,
        rg: this.associateRg,
      });
      this.associateName = "";
      this.associateCpf = "";
      this.associateRg = "";
    },
    handleAddBusinessHours() {
      this.partner.businessHours.push({
        day: this.businessHoursDay,
        endTime: this.businessHoursEndTime,
        startTime: this.businessHoursStartTime,
      });
      this.businessHoursDay = "";
      this.businessHoursEndTime = "";
      this.businessHoursStartTime = "";
    },

    handleBloquedNumber(e: any) {
      const allowedKeys = [
        "0",
        "1",
        "2",
        "3",
        "4",
        "5",
        "6",
        "7",
        "8",
        "9",
        "Backspace",
        "Delete",
        "ArrowLeft",
        "ArrowRight",
      ];
      if (!allowedKeys.includes(e.key)) {
        e.preventDefault();
      }
    },
    handleDeleteAssociate(item: any) {
      this.partner.associate = this.partner.associate.filter((element) => element.cpf !== item.cpf);
    },
    handleDeleteBusinessHours(item: any) {
      this.partner.businessHours = this.partner.businessHours.filter(
        (element, index) => index !== item
      );
    },
    async handleQRCODEStatic() {
      try{
        if (this.partner.digitalAccountNumber == ''){
          throw new Error("É necessário o parceiro adicionar a conta EFI no cadastro")
        }
        const qrdata = `mode=static&${this.partner.document}&${this.partner.name}&${this.partner.id}`
        const data = await QrCode.toDataURL(qrdata)

        const link = document.createElement('a');
        link.href = data;
        link.download = 'pagamentoQRcode.png';
        link.click();
        
      } catch (error:unknown) {
        this.$store.dispatch("showSnack", {
            message: error,
            color: "red",
        });
      }
    },
    async handleQRCODEEnvite() {
      try{
        const qrdata = `https://site.brpoints.com.br/indication/?type=PARTNER&code=${this.partner.indicationKey}`
        const data = await QrCode.toDataURL(qrdata)

        const link = document.createElement('a');
        link.href = data;
        link.download = 'conviteQRcode.png';
        link.click();
        
      } catch (e) {
        this.$store.dispatch("showSnack", {
            message: "Erro ao gerar qrcode",
            color: "red",
        });
      }
    },
  },
  watch: {
    cnaeCode(value) {
      this.partner.cnaeCode = value.replace(/[^0-9]/g, "");
    },
  },
  mounted() {
    if (this.$route.params.id) {
      this.handleLoadPartner();
    }
  },
  computed: {
    isMobile(){
        return this.$vuetify.breakpoint.smAndDown
    }
  }
});
</script>
<style scoped>
.label {
  color: #333 !important;
  font-weight: 600;
  margin-bottom: 2px;
  font-size: 16px;
}

.v-list-item:not(.disabled):hover {
  background: #ccc;
  cursor: pointer;
}
.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.btn-space{
  display: flex;
}

@media (max-width: 768px) {

  .btn-space .v-btn{
    margin-bottom: 10px;
  }
  .size{
    width: calc(100vw - 30px);
  }
}
</style>
@/types/category/Category
